.header {
  position: fixed;
  background-color: #363c3c;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  height: 52px;
  width: 52px;
  margin-left: 30px;
}

.header-search {
  height: 60px;
  width: 320px;
  margin-right: 30px;
  padding: 20px;
  font-size: 24px;
  border-radius: 10px;
}

.content {
  position: fixed;
  padding-top: 100px;
  width: 100%;
  height: 100dvh;
  display: flex;
}

.nav-bar {
  background-color: #d6d8d8;
  min-width: 320px;
  height: 100%;
  padding: 30px;
}

.random-button {
  height: 60px;
  width: 100%;
  border: 1px solid #363c3c;
  border-radius: 10px;
  font-size: 24px;
}

.color-list ul {
  list-style-type: none;
  padding: 0;
}

.color-list li {
  margin-top: 16px;
  font-size: 28px;
}

.swatch-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  overflow-y: scroll;
}

.swatch-cards-container {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
}

.swatch-list-card {
  list-style: none;
  min-width: 220px;
  height: 250px;
}

.swatch-card {
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.swatch-color {
  height: 100%;
  width: 100%;
}

.swatch-label {
  background-color: #fdfdfd;
  height: 60px;
  font-size: 24px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  gap: 32px;
}

.pagination-item {
  font-size: 32px;
}

.pagination-item-selected {
  text-decoration-line: underline;
}

.detail-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 30px;
}

.detail-swatch-card {
  height: 100%;
  width: 100%;
}

.tint-container {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.tint-item {
  height: 175px;
  width: 175px;
}

.clear-selected {
  align-self: center;
  height: 60px;
  min-width: 260px;
  font-size: 24px;
  border-radius: 10px;
}
